<template>
  <v-container fluid class="pa-0 ma-0">
    <v-layout align-center justify-center v-if="loading" class="mt-2">
      <a-spin tip="Loading..."> </a-spin>
    </v-layout>
    <template v-else>
      <v-toolbar color="#f0f0f0">
        <v-toolbar-title>
          <strong>{{ sekkeiData.docNo }}</strong>
        </v-toolbar-title>
        <v-btn
          dark
          rounded
          color="#1565c0"
          small
          @click="CloseTab()"
          class="ml-5"
        >
          <v-icon>mdi-arrow-left</v-icon>Back
        </v-btn>
        <v-btn
          small
          rounded
          @click="print()"
          color="success"
          class="ml-1"
          width="105px"
        >
          <v-icon color="#FAFAFA">mdi-printer</v-icon>Print
        </v-btn>
        <!-- <v-btn class="ml-1" small rounded color="success">
          <v-icon color="yellow">{{
            !isDocFavorite ? "mdi-star-outline" : "mdi-star"
          }}</v-icon
          >Favorite</v-btn
        > -->
        <v-btn
          small
          dense
          rounded
          @click="feedback()"
          color="success"
          class="ml-1"
          width="135px"
        >
          <v-icon color="#FAFAFA">mdi-comment-quote</v-icon>Feedback
        </v-btn>
        <v-spacer />

        <!-- <div v-if="fileLoading">
          <v-progress-circular
            color="green"
            indeterminate
          ></v-progress-circular>
          <span>The File is being Process......</span>
        </div>
        <div style="float:right;" v-if="fileNamePdf && fileLoading == false">
          <span>If the file didnt load </span
          ><span style="color:green;" role="button" @click="getFile()"
            >click here
          </span>
          <v-progress-circular
            v-if="loadAgainLoading"
            indeterminate
            :size="15"
          ></v-progress-circular>
        </div> -->

        <!-- <v-btn
          rounded
          @click="feedback()"
          color="success"
          class="ml-1"
          width="135px"
        >
          <v-icon color="#FAFAFA">mdi-comment-quote</v-icon>Feedback
        </v-btn> -->

        <v-spacer></v-spacer>
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }" class="mr-10">
            <v-icon
              color="#0792b0"
              large
              v-on="on"
              @click="
                historyDialog = true;
                sortHistory();
              "
            >
              mdi-timeline-text
            </v-icon>
          </template>
          <span>View History</span>
        </v-tooltip> -->
      </v-toolbar>
      <!-- <v-alert
        dark
        type="warning"
        dismissible
        v-if="this.excelAlert == true"
        >
        <strong>Note: </strong> If the Excel file doesn't download.
        <v-btn color="info" @click="warningDialog = true">Click Here</v-btn>
      </v-alert> -->

      <v-card tile flat id="print">
        <v-alert
          dismissible
          prominent
          type="error"
          color="#fb8c00"
          dense
          border="left"
          class="mx-1 mt-1"
          elevation="2"
          v-if="this.excelAlert == true"
        >
          <v-row align="center">
            <v-col class="grow">
              <strong>Note: </strong> If the Excel file doesn't download.
              <span
                style="text-decoration: underline; font-weight: 700; cursor: pointer;"
                @click="warningDialog = true"
                >Click here.</span
              >
            </v-col>
            <!-- <v-col class="shrink">
          <v-btn @click="warningDialog = true">Click Here </v-btn>
        </v-col> -->
          </v-row>
        </v-alert>
        <v-alert
          dismissible
          prominent
          type="error"
          color="#fb8c00"
          dense
          border="left"
          class="mx-1 mt-1"
          elevation="2"
          v-if="this.pdfAlert == true"
        >
          <v-row align="center">
            <v-col class="grow">
              <strong>Note: </strong> If the Pdf file failed to download.
              <span
                style="text-decoration: underline; font-weight: 700; cursor: pointer;"
                @click="getFile"
                >Click here.
              </span>
              <v-progress-circular
                v-if="loadAgainLoading"
                indeterminate
                :size="15"
              ></v-progress-circular>
            </v-col>
            <!-- <v-col class="shrink">
          <v-btn @click="warningDialog = true">Click Here </v-btn>
        </v-col> -->
          </v-row>
        </v-alert>
        <v-alert
          dismissible
          prominent
          type="error"
          color="#fb8c00"
          dense
          border="left"
          class="mx-1 mt-1"
          elevation="2"
          v-if="this.folderAlert == true"
        >
          <v-row align="center">
            <v-col class="grow">
              <strong>Note: </strong> If the Folder/zip file doesn't download.
              <span
                style="text-decoration: underline; font-weight: 700; cursor: pointer;"
                @click="getFile"
                >Click here.</span
              >
            </v-col>
            <!-- <v-col class="shrink">
          <v-btn @click="warningDialog = true">Click Here </v-btn>
        </v-col> -->
          </v-row>
        </v-alert>
        <a-row type="flex" justify="start" v-if="hideProperties">
          <a-col>
            <v-btn class="disabled" rounded text>
              <span class="ml-2"> メール アドレス: </span>
            </v-btn>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <a
                  class="ml-4"
                  v-on="on"
                  @click="Copy()"
                  v-clipboard:copy="thingToCopy"
                  >{{ sekkeiData.MailAddress }}
                </a>
              </template>
              <span>Copy mail address to clipboard</span>
            </v-tooltip>

            <v-btn class="disabled" rounded text>
              <span class="ml-5">
                仕様マニュアル更新日：
                <span
                  v-if="sekkeiData.updatedDate != ''"
                  style="text-transform: capitalize;"
                  >Original Copy</span
                >
                <span v-else>{{ sekkeiData.updatedDate }}</span>
              </span>
            </v-btn>
          </a-col>
          <a-col :span="24"><hr /></a-col>
        </a-row>

        <a-row type="flex" justify="start" v-if="hideProperties">
          <a-col :span="6">
            <v-btn rounded text class="disabled">
              <span>区分: {{ sekkeiData.sekkei_classification }}</span>
              <!-- <span v-else
                >建物構法:
                {{ sekkeiData.data[sekkeiData.version - 1].framework }}</span
              > -->
            </v-btn>
          </a-col>

          <a-col :span="6">
            <v-btn rounded text class="disabled">
              <span>項目: {{ sekkeiData.sekkei_projects }}</span>
              <!-- <span v-else
                >資料名:
                {{
                  sekkeiData.data[sekkeiData.version - 1].specifications
                }}</span
              > -->
            </v-btn>
          </a-col>

          <a-col :span="6">
            <v-btn rounded text class="disabled">
              <span>資料種類: {{ sekkeiData.sekkei_materials }}</span>
              <!-- <span v-else
                >部屋・部位:{{
                  roomPart(sekkeiData.data[sekkeiData.version - 1].roomPart)
                }}</span
              > -->
            </v-btn>
          </a-col>

          <!-- <a-col :span="6">
            <v-btn rounded text class="disabled">
              <span v-if="shiyoData.version == 0">しよ番号: {{shiyoData.shiyoNumber}}</span>
              <span v-else>しよ番号: {{shiyoData.data[shiyoData.version - 1].shiyoNumber}}</span>
            </v-btn>
          </a-col> -->
          <a-col :span="24"><hr /></a-col>
        </a-row>
        <!-- ********************************************************************************** -->
        <!-- <v-row no-gutters v-if="hideProperties">
          <v-col cols="2.4">
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].G"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon
              >&nbsp;グラン・セゾン
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].Gsmart"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon
              >&nbsp;グラン・スマート
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].Menshin"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;免震
            </v-btn>
          </v-col>

          <v-col cols="2.4">
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].F"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;セゾンF
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].Ismart"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;i-smart
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].Taishin"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;耐震
            </v-btn>
          </v-col>

          <v-col cols="2.4"> -->
        <!-- <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.data[shiyoData.version - 1].F2TD">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;セゾンF2-TD
            </v-btn> -->
        <!-- <br /> -->
        <!-- <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].Icube"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;i-cube
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].A"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;セゾンA
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].Ismile"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;i-smile
            </v-btn> -->
        <!-- <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.data[shiyoData.version - 1].YumeNoIe">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;夢の家
            </v-btn> -->
        <!-- </v-col>

          <v-col cols="2.4"> -->
        <!-- <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.data[shiyoData.version - 1].Ippan">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;一般
            </v-btn> -->
        <!-- <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].B"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;ブリアール
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon
                v-if="sekkeiData.data[sekkeiData.version - 1].JapaneseStyle"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;和風百年
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="sekkeiData.data[sekkeiData.version - 1].Famille"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close-circle-outline</v-icon
              >&nbsp;ファミーユ（３階建）
            </v-btn>
          </v-col> -->

        <!-- <v-col cols="2.4">
            
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="shiyoData.data[shiyoData.version - 1].Saison">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-close-circle-outline</v-icon>&nbsp;セゾンアシュレ
            </v-btn>
          </v-col> -->

        <!-- <v-col cols="2.4">
         
            <v-icon
              class="py-3"
              color="black"
              v-if="sekkeiData.data[sekkeiData.version - 1].HUGme"
              >mdi-check-circle-outline</v-icon
            >
            <v-icon class="py-3" color="black" v-else
              >mdi-close-circle-outline</v-icon
            >&nbsp;HUGme
            <br />
         
            <v-icon
              color="black"
              v-if="sekkeiData.data[sekkeiData.version - 1].HUGmefam"
              >mdi-check-circle-outline</v-icon
            >
            <v-icon color="black" v-else>mdi-close-circle-outline</v-icon
            >&nbsp;HUGme fam
          
          </v-col>
        </v-row> -->

        <hr />
        <v-layout pt-4 pl-4>
          <p style="font-size: 20px; padding-top: 0; font-weight: bold;">
            件名:
          </p>

          <p
            style="font-size: 20px; padding-top: 0; font-weight: bold;"
            v-html="sekkeiData.title.toUpperCase()"
          ></p>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="hideProperties = !hideProperties">
            <span v-if="!hideProperties"
              >View more details
              <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
            </span>
            <span v-else
              >Hide details <v-icon>mdi-arrow-up-drop-circle-outline</v-icon>
            </span>
          </v-btn>
        </v-layout>
        <hr />

        <v-card
          class="ma-1 pa-1"
          flat
          :height="!hideProperties ? screenSize - 160 : screenSize - 350"
          style="overflow-y: scroll"
        >
          <!-- <v-flex xs12> -->
          <!-- <div v-if="shiyoData.version == 0" class="fr-view" v-html="shiyoData.textContent"></div> -->
          <!-- <div class="fr-view" v-html="shiyoData.data[shiyoData.version - 1].textContent"></div> -->

          <v-flex xs12 v-for="(item, i) in sekkeiData.htmlContent" :key="i">
            <div v-html="item"></div>
          </v-flex>

          <!-- <v-flex>
            <div v-html="sekkeiData.htmlContent"></div>
          </v-flex> -->

          <!-- <div v-else>
              <v-flex v-for="(item,i) in shiyoData.data[shiyoData.version - 1].textContent" :key="i">
                <div v-html="item"></div>
              </v-flex>
            </div> -->
          <v-spacer></v-spacer>
          <!-- </v-flex> -->
        </v-card>

        <!-- <v-card
          class="ma-1 pa-1"
          flat
          :height="!hideProperties ? screenSize - 150 : screenSize - 80"
          style="overflow-y: scroll"
        >
          <editor
            class="px-3"
            :init=tiny_init
            v-model="shiyoData.textContent"
            height="1500"
            id="tinymce_viewing"
          ></editor>
        </v-card> -->
      </v-card>
    </template>

    <v-dialog v-model="historyDialog" max-width="700px" scrollable>
      <v-card>
        <v-toolbar dense flat>
          <span style="font-size: 24px">History Logs </span>
          <v-spacer></v-spacer>
          <v-icon @click="historyDialog = false">mdi-close</v-icon>
        </v-toolbar>

        <a-row type="flex" justify="start">
          <a-col :span="7" style="text-align: center"> Updated Date </a-col>
          <a-col :span="10" style="text-align: center"> Remarks </a-col>
          <a-col :span="7" style="text-align: center"> Updated by </a-col>
        </a-row>
        <template v-for="(item, index) in historyDetails">
          <v-card
            style="cursor: pointer"
            @click="
              viewHistory(index);
              historyDialog = false;
            "
            :key="index"
            flat
          >
            <v-layout
              id="hover"
              @click="searchList = index"
              :class="listColorSearch(index)"
            >
              <v-flex xs12 s6 md7 class="pl-3">
                <!-- {{ index + 1 }}. &nbsp; -->
                {{ index + 1 }}. &nbsp;
                {{ item.updatedDate }}
              </v-flex>
              <v-flex
                xs12
                s6
                md10
                style="text-align: justify; padding-left: 35px"
              >
                {{ item.remarks }}
              </v-flex>
              <v-flex
                xs12
                s6
                md7
                style="text-align: justify; padding-left: 35px"
              >
                {{ item.updatedBy }}
              </v-flex>
            </v-layout>
          </v-card>
        </template>
      </v-card>
    </v-dialog>

    <!-- mike -->
    <v-dialog
      v-model="ViewPdfDialog"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-if="ViewPdfDialog">
        <pdf-viewer
          :closeShiyoDialog="onCloseShiyoDialog"
          :shiyoDetails="sekkeiData"
        />
      </template>
    </v-dialog>

    <template>
      <v-row justify="center">
        <v-dialog v-model="warningDialog">
          <v-toolbar dark color="primary">
            <v-spacer />
            <v-btn icon dark @click="warningDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card
            class="pa-3"
            tile
            :height="$vuetify.breakpoint.height - 200"
            style="overflow-y : auto"
          >
            <v-img
              class="mb-16"
              src="/ShiyoManual_Image/alert2.png"
              width="100%"
              position="center"
            >
            </v-img>

            <!-- <v-img 
              src="/ShiyoManual_Image/alert1.png"
              width="100%"
              position="center"
            >
            </v-img> -->
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

// import Editor from "@tinymce/tinymce-vue";
import tinymce from "tinymce/tinymce";

import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";

// import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";
// import theme from '!!raw-loader!tinymce/themes/silver/theme.min.js'

//mike
// import ShiyoPdfViewer from "../components/ShiyoPdfViewer.vue";
import fileView from "./SekkeiGyoumuFileViewing";
export default {
  title() {
    return `@Rulebook ${this.$route.params.id.substring(0, 9)}`;
  },
  components: {
    // 'editor': Editor,
    // "pdf-viewer": ShiyoPdfViewer,
  },

  name: "newPage",
  props: ["search"],
  data() {
    return {
      tiny_init: {
        object_resizing: false,
        inline: true,
        readonly: 1,
        toolbar: false,
        menubar: false,
        visual: false,
        skin: false,
        content_css: false,
        content_style:
          [contentUiCss].join("\n") +
          "body { font-family:Times New Roman,Arial,sans-serif}",
        // content_style: [contentCss, contentUiCss].join('\n') +'body { font-family:Times New Roman,Arial,sans-serif}',
      },
      fileLoading: false,
      loadAgainLoading: false,
      pdfAlert: false,
      fileNamePdf: "",
      loading: false,
      thingToCopy: `sk-manual@ichijo.co.jp`,
      title_spacer: "　",
      sekkeiData: {
        _id: "",
        _rev: "0",
        documentType: "SekkeiData",
        version: 1,
        data: [
          {
            framework: "",
            productName: "",
            roomPart: "",
            shiyoNumber: "",
            specifications: "",
            textContent: "",
            UpdatedDate: "",
            updatedBy: "",
          },
        ],
      },
      historyDialog: false,
      searchList: "",
      historyDetails: [],
      historyCount: 0,
      hideProperties: true,
      ViewPdfDialog: false,
      warningDialog: false,
      excelAlert: false,
      folderAlert: false,
      folderFileName: "",
      //mike
      // scale: "auto",
      // src: "",
    };
  },
  created() {
    // alert("This part is still on Development  :)");
    this.getData();
    // let testUrl = window.location.href;
    // let url = "";
    // let search = "";
    // let id = this.$route.params.id;
    // if (this.$route.query.search != "") {
    //   search = this.$route.query.search;
    // }
    // console.log(search);
    // this.loading = true;
    // // url = `${this.api}shiyo/get?id=${this.$route.params.id}&search=${search}`;
    // // url = `${this.api}shiyo/get?id=${this.$route.params.id}&search=${search}`;
    // url = `${this.awsURL}sekkeiGyoumu/getSpecificSekkeiGyoumu/${id}`;
    // axios.get(url).then((res) => {
    //   // console.log('line 370 ',res.data)
    //   // document.title = `${res.data.shiyoNumber}-${res.data.productName}`;
    //   document.title = `${res.data.docNo}`;
    //   this.sekkeiData = res.data;
    //   console.log("line 405 ", res.data);
    //   if (res.data.data[res.data.version - 1].isWithPDFAttachment) {
    //     // alert('DITO KA MAG CODE')
    //     this.ViewPdfDialog = true;
    //   } else if (res.data.data[res.data.version - 1].isWithExcelAttachment) {
    //     console.log(
    //       "line 412 nag else if",
    //       res.data.data[res.data.version - 1].isWithExcelAttachment
    //     );
    //     this.excelAlert =
    //       res.data.data[res.data.version - 1].isWithExcelAttachment;
    //     let url =
    //       "https://s3.us-east-2.amazonaws.com/rulebook.files/Excel_files/";
    //     // let newFileName = res.data.data[res.data.version - 1].fileName.split('.')[0]
    //     setTimeout(() => {
    //       window.open(`${url}${res.data.data[res.data.version - 1].fileName}`);
    //       // window.open(`https://www.google.com`, '_blank')
    //     }, 300);
    //   } else {
    //     if (testUrl.match(/index/g)) {
    //       let testSplit = testUrl.split("index");
    //       let getInd = testSplit[1].split("")[1];
    //       this.sekkeiData.version = parseInt(getInd) + 1;
    //     }
    //     let newTextContent = "";
    //     if (this.sekkeiData.version == 0) {
    //       this.sekkeiData.textContent.map((rec) => {
    //         newTextContent += rec;
    //         console.log("newTextContent", newTextContent);
    //       });
    //     } else {
    //       this.sekkeiData.data[this.sekkeiData.version - 1].textContent.map(
    //         (rec) => {
    //           newTextContent += rec;
    //         }
    //       );
    //     }

    //     let tempTextContent = newTextContent.replace(
    //       /href="document/g,
    //       'href="/document'
    //     ); // for rulebook documents si alde po may gawa nyan
    //     this.sekkeiData.textContent = tempTextContent.replace(
    //       /shiyo_document/g,
    //       "/shiyo_document"
    //     );
    //     setTimeout(() => {
    //       tinymce.activeEditor.getBody().setAttribute("contenteditable", false);
    //       //eugene
    //       let table = document.getElementsByTagName("table");
    //       let length = table.length;
    //       for (let i = 0; i <= length - 1; i++) {
    //         let width = table[i].style.width;
    //         if (width.includes("%")) {
    //           let pixelWidth = (parseFloat(width) / 100) * 1200;

    //           let oldAttribute = table[i].getAttribute("style").toString();
    //           let widthIndex = oldAttribute.indexOf("width");

    //           let firstConcat = oldAttribute.substring(0, widthIndex);
    //           let scndConcat = oldAttribute.substring(
    //             widthIndex + 17,
    //             oldAttribute.length
    //           );
    //           table[i].setAttribute(
    //             "style",
    //             `${firstConcat + scndConcat} width: ${pixelWidth - 15}px;`
    //           );
    //         }
    //       }
    //     }, 100);
    //   }
    //   this.loading = false;
    // });
    // }
  },

  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },
  },

  methods: {
    onCloseShiyoDialog() {
      this.ViewPdfDialog = false;
    },
    print() {
      // window.print();
      // console.log(this.$route)
      window.open(`/SekkeiPrint?id=${this.$route.params.id}`);
    },
    feedback() {
      window.open(
        `https://documentsearch.hrd-s.com/feedback?id=${this.$route.params.id}`
      );
    },
    Copy() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Copy mail address to clipboard",
        showConfirmButton: false,
        timer: 800,
      });
    },
    SeeMore() {
      if (this.hideProperties) {
        this.hideProperties = false;
      } else {
        this.hideProperties = true;
      }
    },
    CloseTab() {
      // if (this.sekkeiData.deleted == true) {
      //   this.$router.push(`/deletedshiyo`);
      // } else {
      //   window.close();
      // }
      window.close();
    },

    viewHistory(val) {
      console.log((this.sekkeiData.version = val + 1));
      this.sekkeiData.version = val + 1;
      // this.historyDialog = false
    },
    listColorSearch(id) {
      if (this.searchList == id) {
        return "viewed";
      }
    },
    sortHistory() {
      let viewedShiyoDataHolder = this.sekkeiData.data;
      if (this.historyCount == 0) {
        this.historyDetails = viewedShiyoDataHolder;
        this.historyCount = this.historyCount + 1;
      }
    },

    roomPart(item) {
      if (Array.isArray(item)) {
        let string = " ";
        for (let text of item) {
          string += text + "、　";
        }
        // string = string.substring(string.length, 2)
        return string;
      } else {
        return item;
      }
    },

    async getData() {
      let testUrl = window.location.href;
      let url = "";
      let search = "";
      let id = this.$route.params.id;
      if (this.$route.query.search != "") {
        search = this.$route.query.search;
      }
      console.log(search);
      this.loading = true;

      url = `${this.api}sekkeiGyoumu/getSpecificSekkeiGyoumuHistory/${id}`;
      axios.get(url).then(async (res) => {
        // console.log('line 370 ',res.data)
        // document.title = `${res.data.shiyoNumber}-${res.data.productName}`;
        document.title = `${res.data.docNo}`;
        this.sekkeiData = res.data;
        console.log("line 405 ", res.data);
        this.sekkeiData.htmlContent = this.sekkeiData.htmlContent
          // .split(
          //   `<p><span style="font-size: 17px;"><!-- pagebreak --></span></p>`
          // )
          .split("<!-- pagebreak -->")
          .map((rec, i) => {
            return `<div id=p${i + 1}>${rec}</div>`;
          })
          .map((rec) => {
            return rec.replace(
              /http:\\\\10.11.1.59:2929\\uploads\\/g,
              "https://s3.us-east-2.amazonaws.com/rulebook.files/Shiyo/"
            );
          });
        //for pdf only
        if (res.data.isWithPDFAttachment) {
          this.pdfAlert = true;
          this.fileLoading = true;
          this.fileNamePdf = res.data.attachmentFileName;
          await fileView.getFileSignedUrlViewing(this.fileNamePdf);
          // .then(() => (this.fileLoading = false));
          // .then((res) => {
          //   if (res) {
          //     this.fileLoading = false;
          //   }
          // });
          setTimeout(() => {
            this.fileLoading = false;
          }, 8000);
          //for excel only
        } else if (res.data.folderAttachment) {
          console.log("line 412 nag else if", res.data.folderAttachment);
          this.folderAlert = true;
          this.folderFileName = res.data.folderAttachment;
          let url = `${process.env.VUE_APP_S3LOCATIONS}/SekkeiGyoumuFolders/`;

          setTimeout(() => {
            window.open(`${url}${res.data.folderAttachment}`);
            // window.open(`https://www.google.com`, '_blank')
          }, 300);
        } else if (res.data.isWithExcelAttachment) {
          console.log("line 412 nag else if", res.data.isWithExcelAttachment);
          this.excelAlert = res.data.isWithExcelAttachment;
          let url = `${process.env.VUE_APP_S3LOCATIONS}/Excel_Files_Sekkei/`;
          // let newFileName = res.data.data[res.data.version - 1].fileName.split('.')[0]
          setTimeout(() => {
            window.open(`${url}${res.data.attachmentFileName}`);
            // window.open(`https://www.google.com`, '_blank')
          }, 300);
        } else {
          if (testUrl.match(/index/g)) {
            let testSplit = testUrl.split("index");
            let getInd = testSplit[1].split("")[1];
            this.sekkeiData.version = parseInt(getInd) + 1;
          }
          let newTextContent = "";
          if (this.sekkeiData.version == 0) {
            // this.sekkeiData.textContent.map((rec) => {
            //   newTextContent += rec;
            //   console.log("newTextContent", newTextContent);
            // });
            console.log("lul");
          } else {
            // this.sekkeiData.textContent.map((rec) => {
            //   newTextContent += rec;
            // });
            console.log("lul");
          }

          let tempTextContent = newTextContent.replace(
            /href="document/g,
            'href="/document'
          ); // for rulebook documents si alde po may gawa nyan
          this.sekkeiData.textContent = tempTextContent.replace(
            /sekkei_document/g,
            "/sekkei_document"
          );
          setTimeout(() => {
            tinymce.activeEditor
              .getBody()
              .setAttribute("contenteditable", false);
            //eugene
            let table = document.getElementsByTagName("table");
            let length = table.length;
            for (let i = 0; i <= length - 1; i++) {
              let width = table[i].style.width;
              if (width.includes("%")) {
                let pixelWidth = (parseFloat(width) / 100) * 1200;

                let oldAttribute = table[i].getAttribute("style").toString();
                let widthIndex = oldAttribute.indexOf("width");

                let firstConcat = oldAttribute.substring(0, widthIndex);
                let scndConcat = oldAttribute.substring(
                  widthIndex + 17,
                  oldAttribute.length
                );
                table[i].setAttribute(
                  "style",
                  `${firstConcat + scndConcat} width: ${pixelWidth - 15}px;`
                );
              }
            }
          }, 100);
        }
        this.loading = false;
      });
    },
    closeLoading() {
      this.fileLoading = false;
    },
    getFile() {
      if (this.fileNamePdf) {
        this.loadAgainLoading = true;
        let file = this.fileNamePdf;
        fileView.getFileSignedUrlViewing(file);
        setTimeout(() => {
          this.loadAgainLoading = false;
        }, 3000);
      } else if (this.folderFileName) {
        let url = `${process.env.VUE_APP_S3LOCATIONS}/SekkeiGyoumuFolders/`;
        setTimeout(() => {
          window.open(`${url}${this.folderFileName}`);
        }, 300);
      }
    },
  },
};
</script>

<style scoped>
#hover:hover {
  background-color: #daf5b3;
}

.viewed {
  background-color: #daf5b3;
}
@media only print {
  body {
    position: static;
  }

  div.v-toolbar * {
    display: none !important;
  }
  div.v-toolbar__content * {
    display: none !important;
  }
  nav.v-toolbar.elevation-0.theme--light {
    display: none !important;
  }

  div.ant-tabs-nav-scroll * {
    display: none !important;
  }
  div.v-card.v-card--flat.v-sheet.theme--light {
    margin: 0mm;
    padding: 0mm;
    overflow-y: visible !important;
    position: relative;
  }
}
</style>
